// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

import 'startbootstrap-sb-admin-2/vendor/fontawesome-free/css/all'
import 'startbootstrap-sb-admin-2/css/sb-admin-2'
import 'startbootstrap-sb-admin-2/vendor/jquery-easing/jquery.easing'
import 'startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle'
import 'startbootstrap-sb-admin-2/js/sb-admin-2'
import 'stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
